import { Avatar, Divider, Drawer, IconButton, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, ListSubheader } from "@material-ui/core";
import TickIcon from "@material-ui/icons/Check";
import GetAppIcon from "@material-ui/icons/GetApp";
import HomeIcon from "@material-ui/icons/Home";
import MenuIcon from "@material-ui/icons/Menu";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import React from "react";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import { ALL_CARDS_COOKIE, ALL_CARDS_ID } from "./constants";
import "./Menu.scss";
import { openActionsPdf } from "./utils/openActionsPdf";

export interface MenuProps {
  onShuffle: () => void;
  onTopicChange: (topic: string) => void;
}

const TOPICS = ["rewind", "pause"];

export const Menu = React.memo(function Menu({
  onTopicChange,
  onShuffle
}: MenuProps) {
  const [activeTopic, setActiveTopic] = React.useState<string | undefined>(
    undefined
  );
  const [open, setOpen] = React.useState(false);
  const handleMenuClick = React.useCallback(() => {
    setOpen(!open);
  }, [open, setOpen]);

  const handleDownload = React.useCallback(() => {
    openActionsPdf();
  }, []);

  const handleShuffleClick = React.useCallback(() => {
    onShuffle();
    setOpen(false);
  }, [onShuffle]);

  const handleTopicClick = React.useCallback(
    (topic: string) => {
      topic = activeTopic === topic ? undefined : topic;
      setOpen(false);
      setActiveTopic(topic);
      onTopicChange(topic);
    },
    [activeTopic, onTopicChange]
  );

  const [cookies] = useCookies([ALL_CARDS_COOKIE]);
  const isAllCards = cookies[ALL_CARDS_COOKIE] === ALL_CARDS_ID;

  return (
    <React.Fragment>
      <IconButton onClick={handleMenuClick}>
        <MenuIcon />
      </IconButton>
      <Drawer anchor="top" open={open} onClose={handleMenuClick}>
        <List>
          <ListItem
            button
            key="title"
            className="title"
          >
            <img className="logo" src="/brand.png" alt="The Human Card" />
          </ListItem>
          <Divider />
          {isAllCards
            ? <ListItem button key="home" onClick={() => window.history != null ? window.history.back(): null}>
                <ListItemAvatar>
                  <Avatar>
                    <HomeIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="All Cards" />
              </ListItem>
            : <ListItem button key="home" component={Link} to="/">
                <ListItemAvatar>
                  <Avatar>
                    <HomeIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Home" />
              </ListItem>
          }
          <ListItem button key="shuffle" onClick={handleShuffleClick}>
            <ListItemAvatar>
              <Avatar>
                <PlayArrowIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Start Again" />
          </ListItem>
          <ListItem button key="download" onClick={handleDownload}>
            <ListItemAvatar>
              <Avatar>
                <GetAppIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Download" />
          </ListItem>
          <Divider />
          <ListSubheader>Topics</ListSubheader>
          <ListItem
              button
              key={"all"}
              onClick={() => handleTopicClick(undefined)}
            >
              <ListItemIcon>
                {activeTopic == null ? <TickIcon /> : <div />}
              </ListItemIcon>
              <ListItemText primary={"ALL TOPICS"} />
            </ListItem>
          {TOPICS.map(topic => (
            <ListItem
              button
              key={topic}
              onClick={() => handleTopicClick(topic)}
            >
              <ListItemIcon>
                {activeTopic === topic ? <TickIcon /> : <div />}
              </ListItemIcon>
              <ListItemText primary={topic.toUpperCase()} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </React.Fragment>
  );
});
