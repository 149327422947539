import React from "react";
import { DeckConfig } from "../types";

export const ForTeamsDeck: DeckConfig  ={
  id: "teams",
  name: "for teams",
  color: "#F9F9D4",
  code: "258DD4A5-92EC-4BAB-B519-9871DC125EC0",
  logo: "/brand.png",
  download: "/The Human Card - Team Journal.pdf",
  instructionText: "Take a moment together and work through each question card",
  completeText: "Download and capture your next steps in the action plan",
  topics: [
    {
      id: "rewind",
      label: "Rewind",
      icon: <img src="/rewind-white.png" alt="REWIND" />,
      instructions: "Use the cards to look back and reflect where we have come from",
      cardHeader: <img src="/rewind.png" alt="REWIND" />,
      backgroundImage: "/speech-yellow.png",
      cards: [
        "What six words did people use to describe our business?",
        "Looking back, what are you most proud of?",
        "What was most appreciated by our customers?",
        "Where was our business most profitable?",
        "How would people say you made them feel?",
        "What mattered to you personally?",
      ]
    },
    {
      id: "pause",
      label: "Pause",
      icon: <img src="/pause-white.png" alt="PAUSE" />,
      instructions: "Use the cards to discuss what really matters now as you make key decisions",
      cardHeader: <img src="/pause.png" alt="PAUSE" />,
      backgroundImage: "/speech-yellow.png",
      cards: [
        "What matters to you now?",
        "What can you personally do differently?",
        "What would our customers appreciate most now?",
        "Where can our business be most profitable?",
        "What do we want to be known for?",
        "What matters most as you work through change as a team?",
        "What kind of working environment do we want to create?",
      ]
    }
  ]
}
