import React from "react";
import { DeckConfig } from "../types";

export const ForMeDeck: DeckConfig  ={
  id: "me",
  name: "for me",
  color: "#FCF1DD",
  code: "70DD7E11-77E7-416B-953B-F7B3ABD0EC2F",
  logo: "/brand.png",
  download: "/The Human Card - Personal Journal.pdf",
  instructionText: "Take a moment and work through each question card",
  completeText: "Download and scribble your thoughts in the journal",
  topics: [
    {
      id: "rewind",
      label: "Rewind",
      icon: <img src="/rewind-white.png" alt="REWIND" />,
      instructions: "Use the cards to look back and reflect where you have come from",
      cardHeader: <img src="/rewind.png" alt="REWIND" />,
      backgroundImage: "/speech-orange.png",
      cards: [
        "What kind of work or projects did you enjoy doing?",
        "What made you feel the most energised or inspired?",
        "Looking back, what are you most proud of?",
        "Who did you enjoy working with?",
        "What did people most appreciate about you?",
        "What really mattered to you personally?",
      ]
    },
    {
      id: "pause",
      label: "Pause",
      icon: <img src="/pause-white.png" alt="PAUSE" />,
      instructions: "Use the cards to reflect on what really matters now",
      cardHeader: <img src="/pause.png" alt="PAUSE" />,
      backgroundImage: "/speech-orange.png",
      cards: [
        "What matters to you now?",
        "What do you want work to feel like?",
        "What new opportunities do you see?",
        "What working environment do you want to be part of and create?",
        "Do you want to build upon existing skills or start something new?",
        "What do you want to be known for now?",
        "What have you always dreamed of doing differently, that you can do now?",
      ]
    }
  ]
}
