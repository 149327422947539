import { Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import "./Instructions.scss";

export interface InstructionsProps {
  className?: string;
}

export const Instructions = React.memo(function InstructionsFn({ className }: InstructionsProps) {
  return (
    <Grid
      className={classNames("instructions", className)}
      container
      direction="row"
      justify="center"
      alignItems="center"
    >
      <Grid
        item
        xs={12}
        md={6}
        className="text"
      >
      <Typography variant="h2" className="section-header">How to play</Typography>
      <Typography variant="h5" className="with-line-spacing">
        Get together (virtually) as a team, and take a moment to collectively
      </Typography>
      <List>
        <ListItem>
          <ListItemAvatar>
            <img className="list-icon" src="/rewind-icon.png" alt="Rewind" />
          </ListItemAvatar>
          <ListItemText
            primary="REWIND"
            secondary="Use the cards to look back and reflect where you have come from"
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <img className="list-icon" src="/pause-icon.png" alt="Pause" />
          </ListItemAvatar>
          <ListItemText
            primary="PAUSE"
            secondary="Use the cards to discuss what really matters now as you make key decisions"
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <img className="list-icon" src="/play-icon.png" alt="Play" />
          </ListItemAvatar>
          <ListItemText
            primary="PLAY"
            secondary="Download the action plan and complete as a team to establish what is next"
          />
        </ListItem>
      </List>

          </Grid>
    </Grid>
  );
});
