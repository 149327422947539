import { AppBar, Button, Toolbar } from "@material-ui/core";
import React from "react";
import { logPage } from "./App";
import "./Banner.scss";

export interface BannerProps {
  className?: string;
  hideBuyNow?: boolean;
}

export const Banner = React.memo(function BannerFn({ className, hideBuyNow}: BannerProps) {
  const handleClick = () => {
    logPage("/buy-now");
  }
  return (
    <React.Fragment>
      <AppBar className="banner" color="transparent">
        <Toolbar>
          <div className="left">
            <img className="logo" src="/brand.png" alt="The Human Card" />
          </div>
          {!hideBuyNow && <Button target="_" href="https://b-collective.com/products" onClick={handleClick}>Buy Now</Button>}
        </Toolbar>
      </AppBar>
      <Toolbar />
    </React.Fragment>
  );
});
