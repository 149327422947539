import { DeckConfig } from "../types";
import { DemoDeck } from "./DemoDeck";
import { ForKids10Deck } from "./ForKids10Deck";
import { ForKids5Deck } from "./ForKids5Deck";
import { ForMeDeck } from "./ForMeDeck";
import { ForTeamsDeck } from "./ForTeamsDeck";
import { ForUsDeck } from "./ForUsDeck";

export const DecksConfig: ReadonlyArray<DeckConfig> = [
  DemoDeck,
  ForTeamsDeck,
  ForMeDeck,
  ForUsDeck,
  ForKids5Deck,
  ForKids10Deck,

  // WorkLifeDeck,
];
