import { Button, CssBaseline, Grid } from "@material-ui/core";
import React from "react";
import { useCookies } from "react-cookie";
import { Link } from 'react-router-dom';
import "./AllCards.scss";
import { Banner } from "./Banner";
import { ALL_CARDS_COOKIE, ALL_CARDS_ID } from "./constants";
import { ForKids10Deck } from "./data/ForKids10Deck";
import { ForKids5Deck } from "./data/ForKids5Deck";
import { ForMeDeck } from "./data/ForMeDeck";
import { ForTeamsDeck } from "./data/ForTeamsDeck";
import { ForUsDeck } from "./data/ForUsDeck";
import { Footer } from "./Footer";

const ALL_CARDS = [
  {
    config: ForTeamsDeck,
    image: "/teams.png",
    color: "#e2e028",
  },
  {
    config: ForMeDeck,
    image: "/me.png",
    color: "#f1b756",
  },
  {
    config: ForUsDeck,
    image: "/us.png",
    color: "#f28373",
  },
  {
    config: ForKids5Deck,
    image: "/kids5.png",
    color: "#72cfc1",
  },
  {
    config: ForKids10Deck,
    image: "/kids10.png",
    color: "#76c672",
  }
]

export const AllCards = React.memo(function AllCardsFn() {
  const [cookies, setCookie] = useCookies([ALL_CARDS_COOKIE]);
  React.useEffect(() => {
    setCookie(ALL_CARDS_COOKIE, ALL_CARDS_ID)
  }, []);
  return (
    <div className="all-cards">
      <CssBaseline />
      <Banner hideBuyNow={true} />
      <Grid
        className="grid"
        container
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Grid
        className="grid"
        container
        direction="row"
        alignItems="center"
        spacing={3}
      >
        <Grid />
        {ALL_CARDS.map(card => <Grid key={card.config.id}  item xs={12} sm={6} md={4} lg={2}><Link  className="all-card" to={`/cards/${card.config.id}/${card.config.code}`}>
          <img src={card.image} alt={card.config.name} />
          <Button className="all-button" style={{backgroundColor: card.color}} variant="contained">Play</Button>
        </Link></Grid>)}
        </Grid>
        <Footer />
      </Grid>
    </div>
  );
});
