import { Grid, Link, Typography } from "@material-ui/core";
import React from "react";
import "./AboutUs.scss";


export interface AboutUsProps {
  className?: string;
}

export const AboutUs = React.memo(function AboutUsFn({ className}: AboutUsProps) {
  return (
    <Grid
      container
      className="about-us"
      direction="row"
      justify="center"
      alignItems="center"
    >
      <Grid item xs={12} md={6}>
        <Typography variant="h2" className="section-header">About B-Collective</Typography>

        <Typography variant="body2">
        The work we do is all about people. Our work intersects business culture & brand; it’s all about how people feel working with you, and that's never mattered more than right now.
        </Typography>
        <br />
        <Typography variant="h5">Get in touch</Typography>
        <div>
          <br /><br />
          <Typography variant="subtitle1">Megan Borrie</Typography><br />
          <Link href="mailto:megan@b-collective.com">megan@b-collective.com</Link><br />
          <Link href="tel:+64220487506">+6422 048 7506</Link><br />
          <br />
          <Typography variant="subtitle1">Jane Willersdorf</Typography><br />
          <Link href="mailto:jane@b-collective.com">jane@b-collective.com</Link><br />
          <Link href="tel:+64277222343">+6427 722 2343</Link><br />
          <br />
          <Link target="_blank" href="https://www.b-collective.com">www.b-collective.com</Link>
        </div>
      </Grid>
    </Grid>
  );
});
