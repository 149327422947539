import { AppBar, Toolbar } from "@material-ui/core";
import React from "react";
import "./CardBanner.scss";
import { Menu } from "./Menu";

export interface CardBannerProps {
  className?: string;
  onRestart: () => void;
  onTopicChange: (topic: string) => void;
}

export const CardBanner = React.memo(function CardBannerFn({ className, onRestart: onShuffle, onTopicChange }: CardBannerProps) {

  return (
    <AppBar className="banner" position="static" color="transparent">
      <Toolbar>
        <Menu onShuffle={onShuffle} onTopicChange={onTopicChange} />
        <div className="left">
          <img className="logo" src="/brand.png" alt="The Human Card" />
        </div>
      </Toolbar>
    </AppBar>
  );
});
