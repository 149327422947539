import { Typography } from "@material-ui/core";
import React from "react";
import { DeckConfig } from "../types";

export interface BrandCardProps {
  deck: DeckConfig;
}

export const BrandCard = React.memo(function BrandCardFn({ deck }: BrandCardProps) {
  return (
    <div className="deck-header" style={{backgroundColor: deck.color}}>
      <Typography variant="h2" className="text">{deck.name}</Typography>
      <img className="image" alt="The Human Card" src="/deck-header.png" />
    </div>
  );
});
