import { CssBaseline, Grid } from "@material-ui/core";
import React from "react";
import { AboutUs } from "./AboutUs";
import { Banner } from "./Banner";
import { Footer } from "./Footer";
import { Instructions } from "./Instructions";
import "./Landing.scss";
import { LandingHero } from "./LandingHero";


export const Landing = React.memo(function LandingFn() {
  return (
    <div className="landing">
      <CssBaseline />
      <Banner />
      <Grid
        className="landing-grid"
        container
        direction="column"
        alignItems="center"
      >
        <LandingHero className="section" />
        <Instructions  className="section" />
        <AboutUs className="section" />
        <Footer />
      </Grid>
    </div>
  );
});
