import { createBrowserHistory } from 'history';
import React from "react";
import ReactGA from 'react-ga';
import { Route, Router, Switch } from "react-router-dom";
import { AllCards } from "./AllCards";
import "./App.scss";
import { ALL_CARDS_ID } from './constants';
import { DecksConfig } from "./data/Decks";
import { HumanCards } from "./HumanCards";
import { Landing } from "./Landing";
import { DeckConfig } from "./types";


const trackingId = "UA-164653531-1";
ReactGA.initialize(trackingId);

const history = createBrowserHistory();

// Initialize google analytics page view tracking
history.listen(location => {
  logPage(location.pathname);
});

export function logPage(page: string) {
  ReactGA.set({ page });
  ReactGA.pageview(page);
}

export interface AppProps {
  className?: string;
}

export const App = React.memo(function AppFn({ className }: AppProps) {
  React.useEffect(() => {
    logPage(window.location.pathname);
  }, []);
  return (
    <Router history={history}>
      <Switch>
          {DecksConfig.map(deckConfig => <Route key={deckConfig.id} path={getPath(deckConfig)}>
            <HumanCards deck={deckConfig}/>
          </Route>)}
          <Route path={`/all/${ALL_CARDS_ID}`}>
            <AllCards />
          </Route>
          <Route path="/">
            <Landing />
          </Route>
        </Switch>
    </Router>
  );
});

function getPath(deckConfig: DeckConfig) {
  const suffix = deckConfig.code ? `/${deckConfig.code}` : "";
  return `/cards/${deckConfig.id}${suffix}`
}
