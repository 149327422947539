import { Typography } from "@material-ui/core";
import React from "react";
import { TopicConfig } from "../types";

export interface TopicInstructionsCardProps {
  className?: string;
  topic: TopicConfig;
}

export const TopicInstructionsCard = React.memo(function TopicInstructionsCardFn({ topic }: TopicInstructionsCardProps) {
  return (
    <div className="card-instruction">
      <div className="card-instruction-header">{topic.icon}</div>
      <Typography variant="h4" className="card-instruction-content">{topic.instructions}</Typography>
    </div>
  );
});
