import { Button, Grid, Typography } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { Link } from 'react-router-dom';
import "./LandingHero.scss";

export interface LandingHeroProps {
  className?: string;
}

export const LandingHero = React.memo(function LandingHeroFn({ className}: LandingHeroProps) {
  return (
    <Grid
      container
      className={classNames("landing-hero", className)}
      direction="row"
      justify="center"
      alignItems="center"
    >
      <Grid
        item
        xs={12}
        md={6}
        className="left"
      >
        <div><img className="logo" src="/brand.png" alt="The Human Card" /></div>
        <div className="sub-title">
        <Typography variant="h5">"Playing the human card has never been more important."</Typography>
        <Typography variant="h5" className="with-line-spacing">This simple card game is designed to create meaningful team conversations to help your business navigate during this unprecedented time.</Typography>
        </div>
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        className="right"
      >
        <Link to="/cards/demo">
          <img className="logo" src="/cards.png" alt="Cards" />
        </Link>
        <div className="buttons">
          <Button className="button" variant="contained" color="primary" component={Link} to="/cards/demo">Try it now</Button>
        </div>
      </Grid>
    </Grid>
  );
});
