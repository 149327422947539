import React from "react";
import { DeckConfig } from "../types";

export const ForKids5Deck: DeckConfig  ={
  id: "kids5",
  name: "for kids (5yrs+)",
  color: "#E3F5F3",
  code: "0B1695D2-E9F1-4C39-84D0-420623231E07",
  logo: "/brand.png",
  download: "/The Human Card - Family & Kids Journal.pdf",
  instructionText: "Take a moment with your kids and work through each question card",
  completeText: "Download and scribble your thoughts in the journal",
  topics: [
    {
      id: "rewind",
      label: "Rewind",
      icon: <img src="/rewind-white.png" alt="REWIND" />,
      instructions: "Use the cards to think back to last year",
      cardHeader: <img src="/rewind.png" alt="REWIND" />,
      backgroundImage: "/speech-blue.png",
      cards: [
        "Can you think of three things that made you smile?",
      ]
    },
    {
      id: "pause",
      label: "Pause",
      icon: <img src="/pause-white.png" alt="PAUSE" />,
      instructions: "Use the cards to discuss how you feel now",
      cardHeader: <img src="/pause.png" alt="PAUSE" />,
      backgroundImage: "/speech-blue.png",
      cards: [
        "What do you like doing?",
        "What do you like about school at the moment?",
        "What is the best thing about today?",
        "What is something fun we can we do together as a family?",
        "How can we help each other?",
      ]
    }
  ]
}
