import React from "react";
import { DeckConfig } from "../types";

export const DemoDeck: DeckConfig  ={
  id: "demo",
  name: "demo",
  color: "#E3F5F3",
  logo: "/brand.png",
  download: "/The Human Card - Friends & Family Journal.pdf",
  instructionText: "Take a moment together and work through each question card",
  completeText: "Download and scribble your thoughts in the journal",
  topics: [
    {
      id: "rewind",
      label: "Rewind",
      icon: <img src="/rewind-white.png" alt="REWIND" />,
      instructions: "Use the cards to look back and reflect where you have come from",
      backgroundImage: "/speech-blue.png",
      cardHeader: <img src="/rewind.png" alt="REWIND" />,
      cards: [
        "Looking back, what are you most proud of?",
        "What mattered to you personally?",
        "What did you most appreciate about me?",
        "Can you think of three memories that made you feel happy?",
      ]
    },
    {
      id: "pause",
      label: "Pause",
      icon: <img src="/pause-white.png" alt="PAUSE" />,
      instructions: "Use the cards to discuss what really matters now",
      backgroundImage: "/speech-blue.png",
      cardHeader: <img src="/pause.png" alt="PAUSE" />,
      cards: [
        "What have you always dreamed of doing differently, that we can do now?",
        "Do you want to build upon existing skills or start something new?",
        "How can I support you?",
        "How can I help you feel okay today?",

      ]
    }
  ]
}
