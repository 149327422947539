import { Grid, Typography } from "@material-ui/core";
import React from "react";
import "./Footer.scss";

export interface FooterProps {
  className?: string;
}

export const Footer = React.memo(function FooterFn({ className }: FooterProps) {
  return (
    <Grid
      className="footer"
    >
      <Typography>© 2020 B-Collective</Typography>
    </Grid>
  );
});
