import React from "react";
import { DeckConfig } from "../types";

export const ForUsDeck: DeckConfig  ={
  id: "us",
  name: "for us",
  color: "#FCE6E3",
  code: "EBB0B94E-8BB7-4806-AE11-E058FBCB84E8",
  logo: "/brand.png",
  download: "/The Human Card - Couples Journal.pdf",
  instructionText: "Take a moment and work through each question card",
  completeText: "Download and scribble your thoughts in the journal",
  topics: [
    {
      id: "rewind",
      label: "Rewind",
      icon: <img src="/rewind-white.png" alt="REWIND" />,
      instructions: "Use the cards to look back and reflect where you have come from",
      cardHeader: <img src="/rewind.png" alt="REWIND" />,
      backgroundImage: "/speech-red.png",
      cards: [
        "Looking back, what are you most proud of?",
        "What made you feel the most energised or inspired?",
        "What did you most appreciate about me?",
        "What really mattered to you?",
        "Do you wish you'd spent your time differently?",
        "How do you think you made me feel?",
      ]
    },
    {
      id: "pause",
      label: "Pause",
      icon: <img src="/pause-white.png" alt="PAUSE" />,
      instructions: "Use the cards to reflect on what really matters now",
      cardHeader: <img src="/pause.png" alt="PAUSE" />,
      backgroundImage: "/speech-red.png",
      cards: [
        "What matters to you now?",
        "How do you want me to make you feel right now?",
        "What do you most appreciate about me now?",
        "How can I support you?",
        "What do you want our time together to look like?",
        "What have you always dreamed of doing differently, that we can do now?",
      ]
    }
  ]
}
