import React from "react";
import { DeckConfig } from "../types";

export const ForKids10Deck: DeckConfig  ={
  id: "kids10",
  name: "for kids (10yrs+)",
  color: "#E4F4E3",
  code: "5F4F7CF0-A44D-4D0F-B4CD-6F055DBDC02B",
  logo: "/brand.png",
  download: "/The Human Card - Kids 10+ Journal.pdf",
  instructionText: "Take a moment with your kids and work through each question card",
  completeText: "Download and scribble your thoughts in the journal",
  topics: [
    {
      id: "rewind",
      label: "Rewind",
      icon: <img src="/rewind-white.png" alt="REWIND" />,
      instructions: "Use the cards to think back to last year",
      cardHeader: <img src="/rewind.png" alt="REWIND" />,
      backgroundImage: "/speech-green.png",
      cards: [
        "What did you love doing with your friends?",
        "Can you think of three memories that made you feel happy?",
        "What do you miss?",
      ]
    },
    {
      id: "pause",
      label: "Pause",
      icon: <img src="/pause-white.png" alt="PAUSE" />,
      instructions: "Use the cards to discuss how you feel now",
      cardHeader: <img src="/pause.png" alt="PAUSE" />,
      backgroundImage: "/speech-green.png",
      cards: [
        "What do you enjoy doing now?",
        "What do you like about school at the moment?",
        "What do you love about your family?",
        "What can we all do together for a laugh?",
        "How can I help you feel okay today?",
        "Is there anything we could do to help each other?",
      ]
    }
  ]
}
