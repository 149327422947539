import { Button } from "@material-ui/core";
import React from "react";
import { Card } from "../Card";
import { useIsDemo } from "../hooks/useIsDemo";
import { DeckConfig } from "../types";
import { openPdf } from "../utils/openPdf";

export interface CompleteCardProps {
  deck: DeckConfig;
}

export const CompleteCard = React.memo(function CompleteCardFn({ deck }: CompleteCardProps) {
  const isDemo = useIsDemo();
  const handleClick = React.useCallback(() => {
    openPdf(deck.download);
  }, [deck]);
  return (
    <Card
      topic={"instruction"}
      header={<img src="/play.png" alt="PLAY" />}
      content={(
        <div className="download">
          <div>{deck.completeText}</div>
        {isDemo ? (
          <Button className="download-button" target="_" href="https://b-collective.com/products" size="large" variant="outlined">Buy Now</Button>
        ): (
          <Button className="download-button" onClick={handleClick} size="large" variant="outlined">Download</Button>
        )}
      </div>
      )}
    />
  );
});
