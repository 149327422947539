import classNames from "classnames";
import React from "react";
import "./Cards.scss";

export interface CardProps {
  topic: string;
  header: React.ReactNode;
  content: React.ReactNode;
  backgroundImage?: string;
}

export const Card = React.memo(function Card({ topic, header, content, backgroundImage }: CardProps) {
  return (
    <div className="card">
      {header != null && <div className="header">{header}</div>}
      <div className={classNames("content")}>
        <span className={classNames("content", { "topic-content": topic !== "instruction" })}>{content}</span>
        {backgroundImage != null && topic !== "brand" && topic !== "instructions" && <img className="background-image" alt="" src={backgroundImage} />}
      </div>
      {topic !== "brand" && <div className="brand"><img src="/brand.png" alt="The Human Card" /></div>}
    </div>
  );
});
