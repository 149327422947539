import { Typography } from "@material-ui/core";
import React from "react";
import { DeckConfig } from "../types";

export interface InstructionsCardProps {
  className?: string;
  deck: DeckConfig;
}

export const InstructionsCard = React.memo(function InstructionsCardFn({ deck }: InstructionsCardProps) {
  return (
    <div className="card-instruction">
      <Typography variant="h2" className="card-instruction-header">How to play</Typography>
      <Typography variant="h4" className="card-instruction-content">{deck.instructionText}</Typography>
    </div>
  );
});
