import React from "react";
import "./App.scss";
import { CardBanner } from "./CardBanner";
import { BrandCard } from "./cards/BrandCard";
import { CompleteCard } from "./cards/CompleteCard";
import { InstructionsCard } from "./cards/InstructionsCard";
import { TopicInstructionsCard } from "./cards/TopicInstructionsCard";
import { Deck } from "./Deck";
import { CardContent, DeckConfig, TopicConfig } from "./types";

const oneTopic = (deck: DeckConfig, topic: string) =>
  [...buildCards(deck)].filter(content => topic.trim() === content.topic.trim()).reverse();

function buildTopic(topic: TopicConfig): ReadonlyArray<CardContent> {
  const cards: CardContent[] = [
    { topic: "instructions", content: <TopicInstructionsCard topic={topic} /> },
    ...topic.cards.map(card => ({
      topic: topic.id,
      header: topic.cardHeader,
      content: card,
      backgroundImage: topic.backgroundImage,
    }))
  ];
  return cards
}

function buildCards(deck: DeckConfig): ReadonlyArray<CardContent> {
  const cards: CardContent[] = [
    { topic: "instructions", content: <BrandCard deck={deck} />  },
    { topic: "instructions", content: <InstructionsCard deck={deck} /> },
  ];
  deck.topics.forEach(topic => {
    for (const card of buildTopic(topic)) {
      cards.push(card);
    }
  })
  return cards.reverse();
}

export interface HumanCardsProps {
  deck: DeckConfig;
}

export const HumanCards = React.memo(function HumanCards({ deck }: HumanCardsProps) {
  const [topicFilter, setTopicFilter] = React.useState<string | undefined>(
    undefined
  );
  const [cards, setCards] = React.useState<
    ReadonlyArray<CardContent>
  >(buildCards(deck));

  const filterCards = React.useCallback(
    (topic: string | undefined) => {
      console.log(topic);
      const filteredContents =
        topic !== undefined ? oneTopic(deck, topic) : buildCards(deck);
      setCards(filteredContents);
      setTopicFilter(topic);
    },
    [deck, setTopicFilter, setCards]
  );

  const handleRestart = React.useCallback(() => filterCards(topicFilter), [
    topicFilter,
    filterCards
  ]);

  const handleTopicChange = React.useCallback(
    topic => {
      filterCards(topic);
    },
    [filterCards]
  );
  return (
    <React.Fragment>
      {/* <Menu onShuffle={handleShuffle} onTopicChange={handleTopicChange}/> */}
      <CardBanner onRestart={handleRestart} onTopicChange={handleTopicChange} />
      <Deck cards={cards} completeCard={<CompleteCard deck={deck} />} onRestart={handleRestart} />
    </React.Fragment>
  );
});

