import { Button } from "@material-ui/core";
import PreviousIcon from "@material-ui/icons/ChevronLeftRounded";
import NextIcon from "@material-ui/icons/ChevronRightRounded";
import React, { useEffect, useState } from "react";
import { animated, interpolate, useSprings } from "react-spring";
import { useGesture } from "react-use-gesture";
import { Card } from "./Card";
import "./styles.scss";
import { CardContent } from "./types";

export interface DeckProps {
  cards: ReadonlyArray<CardContent>;
  completeCard: React.ReactNode;
  onRestart: () => void;
}

// These two are just helpers, they curate spring data, values that are later being interpolated into css
const to = (i: number) => ({
  x: 0,
  y: i * -1,
  scale: 1,
  rot: -5 + Math.random() * 10,
  delay: i * 30
});
const from = (i: number) => ({ x: 0, rot: 0, scale: 1.5, y: -1000 });
// This is being used down there in the view, it interpolates rotation and scale into a css transform
const trans = (r: number, s: number) =>
  `perspective(1500px) rotateX(15deg) rotateY(${r /
    10}deg) rotateZ(${r}deg) scale(${s})`;

export const Deck = React.memo(function Deck({ cards, completeCard }: DeckProps) {
  const [complete, setComplete] = useState(false);
  const [gone] = useState(() => new Set<number>()); // The set flags all the cards that are flicked out
  const [currentIndex, setCurrentIndex] = useState(cards.length - 1);

  const [props, set] = useSprings<any>(cards.length, i => ({
    ...to(i),
    from: from(i)
  })); // Create a bunch of springs using the helpers above

  useEffect(() => {
    setComplete(false);
    gone.clear();
    set((i: number) => to(i));
  }, [cards, gone, set, setComplete]);

  const handleCardFlick = React.useCallback(({
    down,
    index,
    dir,
    velocity,
    xDelta
  }) => {
    const trigger = velocity > 0.2; // If you flick hard enough it should trigger the card to fly out
    // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
    if (!down && trigger) {
      gone.add(index);
    }
    set((i: any) => {
      if (index !== i) return; // We're only interested in changing spring-data for the current spring
      const isGone = gone.has(index);
      const x = isGone ? (200 + window.innerWidth) * dir : down ? xDelta : 0; // When a card is gone it flys out left or right, otherwise goes back to zero
      const rot = xDelta / 100 + (isGone ? dir * 10 * velocity : 0); // How much the card tilts, flicking it harder makes it rotate faster
      const scale = down ? 1.1 : 1; // Active cards lift up a bit
      return {
        x,
        rot,
        scale,
        delay: undefined,
        config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 }
      };
    });
    if (!down && gone.size === cards.length) {
      setTimeout(() => {
        setComplete(true);
      }, 200);
    }
  }, [set, cards, setComplete, gone])

  // Create a gesture, we're interested in down-state, delta (current-pos - click-pos), direction and velocity
  const bind = useGesture(
    ({
      args: [index],
      down,
      delta: [xDelta],
      distance,
      direction: [xDir],
      velocity
    }) => {
      const dir = xDir < 0 ? -1 : 1; // Direction should either point left or right
      handleCardFlick({
        down,
        index,
        dir,
        velocity,
        xDelta
      })
      setCurrentIndex(index - 1);
    }
  );

  const handlePreviousCard = React.useCallback(({
    index,
  }) => {
    set((i: any) => {
      if (index !== i - 1) return; // We're only interested in changing spring-data for the previous spring
      const x = 0; // When a card is gone it flys out left or right, otherwise goes back to zero
      const rot = -5 + Math.random() * 10;
      const scale = 1;
      return {
        x,
        rot,
        scale,
        delay: undefined,
        config: { friction: 50, tension: 500 }
      };
    });
  }, [set])

  const handlePrevious = React.useCallback(() => {
    const index = currentIndex;

    handlePreviousCard({ index })
    gone.delete(index);
    gone.delete(index + 1);
    setCurrentIndex(index + 1);
  }, [currentIndex, gone, handlePreviousCard, setCurrentIndex])

  const handleNext = React.useCallback(() => {
    const index = currentIndex;
    const xDelta = 50;
    const dir = 1;
    const velocity = 0.5;
    const down = false;

    handleCardFlick({
      down,
      index,
      dir,
      velocity,
      xDelta
    })
    setCurrentIndex(index - 1);
  }, [currentIndex, handleCardFlick, setCurrentIndex])
  // Now we're just mapping the animated values to our view, that's it. Btw, this component only renders once. :-)
  return (
    <div className="deck-container">
      {gone.size !== 0 && <Button className="deck-prev-button" onClick={handlePrevious} size="large" startIcon={<PreviousIcon />}>
        Prev
      </Button>}
      <div className="deck">
        {complete && (
          <div className="complete">{completeCard}</div>
        )}
        {props.map(({ x, y, rot, scale }, i) => (
          <animated.div
            className="card-container"
            key={i}
            style={{
              transform: interpolate(
                [x, y],
                (x, y) => `translate3d(${x}px,${y}px,0)`
              )
            }}
          >
            {/* This is the card itself, we're binding our gesture to it (and inject its index so we know which is which) */}
            <animated.div
              {...bind(i)}
              style={{
                transform: interpolate([rot, scale], trans)
              }}
            >
              <Card topic={cards[i].topic} header={cards[i].header} content={cards[i].content} backgroundImage={cards[i].backgroundImage} />
            </animated.div>
          </animated.div>
        ))}
      </div>
      {!complete && <Button className="deck-next-button" onClick={handleNext} size="large" endIcon={<NextIcon />}>
        Next
      </Button>}
    </div>
  );
});
